import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
function setCsp(stsUri, cspAllowedFrameSrc) {
    const cspContent = `
        default-src 'self';
        connect-src 'self' https://api.addressy.com ${environment.discovery.url} ${environment.presentation.url} ${environment.presentation.websocket} ${environment.document.url} ${stsUri};
        frame-src ${environment.webhook.url} ${environment.payments.pay360.sandbox} ${environment.payments.pay360.production} ${environment.payments.stripe} ${cspAllowedFrameSrc};
        img-src 'self' data: https://lfsstatics.z33.web.core.windows.net;
        script-src 'self' 'unsafe-inline' 'unsafe-eval' https://cdn.jsdelivr.net https://js.stripe.com;
        style-src 'self' 'unsafe-inline' https://lfsstatics.z33.web.core.windows.net https://fonts.googleapis.com;
        font-src 'self' https://fonts.gstatic.com;
        worker-src 'self' blob:;
      `;
    const cspMetaTag = document.createElement("meta");
    cspMetaTag.httpEquiv = "Content-Security-Policy";
    cspMetaTag.content = cspContent.trim();
    document.head.appendChild(cspMetaTag);
}
const clientInfoRequest = new XMLHttpRequest();
clientInfoRequest.open("GET", `${environment.discovery.url}/services/discover`, true);
clientInfoRequest.send();
clientInfoRequest.onreadystatechange = function readyStateChanged() {
    if (this.readyState === 4) {
        if (this.status === 200) {
            const clientConfig = JSON.parse(this.responseText);
            setCsp(clientConfig.stsUri, clientConfig.cspAllowedFrameSrc);
            environment.clientId = clientConfig.clientId;
            environment.stsUri = clientConfig.stsUri;
            environment.title = clientConfig.title || "No Title Set";
            environment.useMilestones = clientConfig.useMilestones;
            environment.useQuotes = clientConfig.useQuotes;
            environment.usePrivacySettings = clientConfig.usePrivacySettings;
            environment.supportedCommunicationPreference =
                clientConfig.supportedCommunicationPreference;
        }
        environment.statusCode = this.status;
        __NgCli_bootstrap_2.platformBrowser()
            .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
            .catch((err) => console.log(err));
    }
};
