export const environment = {
  production: true,
  discovery: {
    url: "#{LFSPortalUI-DiscoveryUrl}#",
  },
  presentation: {
    url: "#{LFSPortalUI-APIBaseAddress}#",
    websocket: "#{LFSPortalUI-WebsocketBaseAddress}#",
  },
  document: {
    url: "#{LFSPortalUI-DocumentBaseAddress}#",
  },
  webhook: {
    url: "#{LFSPortalUI-WebhookBaseAddress}#",
  },
  payments: {
    pay360: {
      production: "https://secure.pay360.com",
      sandbox: "https://secure.mite.pay360.com https://dev.mite.pay360.com",
    },
    stripe: "https://js.stripe.com",
  },

  postCodeAnywhereKey: "#{LFSPortalUI-PCAKey}#",
  scopes:
    "openid profile offline_access step.mine form.fetch me.read activity.reader step.reader document.generate matter.reader milestoneplan.reader",

  clientId: null,
  statusCode: null,
  stsUri: null,
  title: null,
  useQuotes: null,
  useMilestones: null,
  usePrivacySettings: null,
  supportedCommunicationPreference: null,
};
